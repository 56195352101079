<template>
	<v-container fluid>
		<v-card class="home">
			<v-data-table
				:headers="headers"
				:items="filteredPages"
				:search="search"
			>
				<template v-slot:top>
					<v-toolbar flat>
						<v-row no-gutters>
							<v-col cols="8" class="pr-4 mt-10">
								<v-text-field
									v-model="search"
									dense
									outlined
									label="Search URL slug"
									placeholder="Please Search the URL"
								></v-text-field>
							</v-col>

							<v-col cols="4" class="pl-4 mt-10">
								<v-select
									:items="pageTypes"
									dense
									outlined
									label="Select Page Type"
									v-model="pageType"
								></v-select>
							</v-col>
						</v-row>

						<v-dialog v-model="dialog" max-width="700px">
							<v-card>
								<v-card-title>
									<span class="text-h5">
										Optimization Score
									</span>
								</v-card-title>

								<v-card-text>
									<v-row>
										<v-col cols="12">
											<div>
												<v-col
													class="
														d-flex
														justify-center
													"
												>
													<v-progress-circular
														:size="150"
														:width="25"
														:value="result.score"
														class="scoreTitle"
														:color="scoreColor"
													>
														{{ result.score }}
													</v-progress-circular>
												</v-col>
												<p class="text-center">
													<strong>Page URL:</strong>

													{{ editedItem.slug }}
												</p>
											</div>
											<p class="text-h5 mt-5">
												Suggestions:
											</p>
											<ul class="mt-5">
												<li
													v-for="(
														suggestion, i
													) in result.suggestions"
													:key="i"
													class="
														text-subtitle-1
														font-weight-medium
													"
												>
													{{ suggestion }}
												</li>
											</ul>
										</v-col>
									</v-row>
								</v-card-text>

								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										color="blue darken-1"
										text
										@click="close"
									>
										Okay
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-btn color="success" @click="analyzePage(item)" small>
						Analyze
						<v-icon right> mdi-search-web </v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-card>
	</v-container>
</template>

<script>
export default {
	data: () => ({
		search: '',
		pageType: 'All',
		pageTypes: [],
		dialog: false,
		dialogDelete: false,
		headers: [
			{
				text: 'Slug',
				value: 'slug',
				align: 'start',
				sortable: false,
			},
			{ text: 'Page Type', value: 'pageType', filterable: false },
			{
				text: 'Actions',
				value: 'actions',
				sortable: false,
				filterable: false,
			},
		],
		pages: [],
		editedIndex: -1,
		editedItem: {
			pageType: '',
			slug: '',
		},
		defaultItem: {
			pageType: '',
			slug: '',
		},
		result: {
			score: '',
			suggestions: [],
		},
	}),
	computed: {
		scoreColor() {
			let color = ''
			if (this.result.score <= 35) {
				color = 'red'
			} else if (this.result.score > 35 && this.result.score <= 70) {
				color = 'orange'
			} else {
				color = 'green'
			}
			return color
		},
		filteredPages() {
			if (this.pageType === 'All') {
				return this.pages
			}

			return this.pages.filter((i) => {
				return !this.pageType || i.pageType === this.pageType
			})
		},
	},
	watch: {
		dialog(val) {
			val || this.close()
		},
		dialogDelete(val) {
			val || this.closeDelete()
		},
	},
	created() {
		this.getPageLinks()
	},
	methods: {
		getPageLinks() {
			const projectId = this.$store.state.app.projectId
			this.axios
				.get(`/${projectId}/microservice/seo/content-freshness/links`)
				.then((res) => {
					// Set list of page links
					this.pages = res.data.data

					// Set list of page types for dropdown filter
					this.pageTypes = res.data.data.map((obj) => obj.pageType)
					// Add another 'All' option in dropdown filter
					this.pageTypes.unshift('All')
				})
				.catch((err) => {
					console.log(err.response.data)
				})
		},
		analyzePage(item) {
			const projectId = this.$store.state.app.projectId
			const domain = this.$store.getters['app/getCurrentProjectDomain']

			this.axios
				.post(`/${projectId}/microservice/seo/content-freshness`, {
					type: item.pageType,
					domain: domain,
					slug: item.slug,
				})
				.then((response) => {
					console.log(response.data)
					this.result.score = response.data.results.score
					this.result.suggestions = response.data.results.suggestions
				})
			this.editedIndex = this.pages.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true
		},
		close() {
			this.dialog = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
	},
}
</script>

<style>
.container {
	height: 100%;
}
.v-card.home {
	height: 100%;
}
li {
	list-style-type: disc;
}
.text-h5 {
	color: #522e8f !important;
	font-weight: 500 !important;
}
.scoreTitle {
	font-size: 35px !important;
	font-weight: 500 !important;
}
tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}
</style>
